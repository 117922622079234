.modal-wrapper {
	left: 0;
	top: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #000000c0;
	z-index: 9999999;
	display: flex;
}
.modal-body {
	margin: auto;
	background-color: #ffffff;
	border-radius: 10px;
	max-width: 500px;
}
.modal-content {
	padding: 2rem;
	font-size: 1.5rem;
	overflow-y: scroll;
}
.modal-buttons {
	border-radius: 0 0 10px 10px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
.modal-buttons button {
	font-size: 1.5rem;
	border: 0;
	padding: 1rem;
	color: #222;
	cursor: pointer;
}
.modal-buttons button:hover {
	text-decoration: underline;
}
.modal-buttons button:first-child {
	border-radius: 0 0 0 10px;
	background-color: #eee;
}
.modal-buttons button:last-child {
	border-radius: 0 0 10px 0;
	background-color: #daa520;
}
