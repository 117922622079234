.search-box {
	display: grid;
	grid-template-columns: repeat(1, 12fr);
}
.search-fieldset {
	padding: 0;
	border: 0;
	display: grid;
	grid-template-columns: repeat(1, 7fr 4fr 1fr);
}
.search-fieldset input {
	border: 1px solid #555;
}
.search-fieldset input:first-child {
	border-radius: 10px 0 0 10px;
}
.search-fieldset button {
	margin: 5px 0;
	display: flex;
	border-radius: 0 10px 10px 0;
}
.search-fieldset button span {
	margin: auto;
	font-size: 1.5rem;
}
.search-suggestions {
	color: #555;
	font-weight: 600;
	padding: 0.5rem 1rem;
	margin-top: -1rem;
	margin-bottom: 1rem;
	border-bottom: 5px solid #daa520;
	border-radius: 0 0 15px 15px;
}
