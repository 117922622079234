.spinner-container {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #00000080;
	display: flex;
	box-sizing: border-box;
}
.spinner {
	font-size: 2rem;
	color: #daa520;
	margin: auto;
	text-align: center;
}
