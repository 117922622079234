header {
	background-color: #daa520;
	color: #000;
	min-height: 10vh;
	display: grid;
	grid-template-columns: 1fr;
	border-bottom: 6px solid #444040;
}
.header-container {
	display: grid;
	grid-template-columns: 10fr 2fr;
	gap: 1rem;
	align-items: center;
	padding: 1rem;
}
.header-container h1 {
	margin: 0;
	display: inline;
	cursor: pointer;
}
.brand {
	text-align: left;
}
.hamburger {
	text-align: right;
}
