nav {
	position: absolute;
	width: 100%;
	height: 90vh;
	z-index: 5999;
	background-color: #555;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
}
nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
nav ul li {
	text-align: center;
	margin: 3rem auto;
}
nav ul li a {
	margin: auto;
	color: #daa520;
	font-weight: bold;
}
nav ul li a:hover,
.active {
	text-decoration: underline;
}
