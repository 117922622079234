* {
	box-sizing: border-box;
}
body {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-family: Verdana, Tahoma, sans-serif;
	color: #555;
}
a {
	color: inherit;
	text-decoration: none;
}
h1 {
	font-size: 2rem;
}
h2 {
	font-size: 1.5rem;
}
h3 {
	font-size: 1.3rem;
}
p {
	font-size: 1rem;
	font-family: Geneva, Verdana, sans-serif;
}
main {
	min-height: 80vh;
	padding: 1rem;
}
input:focus {
	outline: none !important;
	border: 1px solid #daa520 !important;
}
.form-control {
	font-size: 1.5rem;
	width: 100%;
	padding: 10px 15px;
	margin: 5px 0;
	box-sizing: border-box;
}
.centered {
	text-align: center;
}
.interviews-ul {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 2rem;
}
.link {
	color: #daa520;
	font-weight: bold;
	cursor: pointer;
}
