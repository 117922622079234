.interview-li:nth-child(odd) {
	background-color: #eee;
}
.interview-li {
	margin: 1.5rem 0;
	border-bottom: 2px solid #daa520;
	display: grid;
	grid-template-columns: 1fr 11fr;
}
.interview-li p {
	cursor: pointer;
	display: inline;
	margin-top: 0;
	font-size: 1.3rem;
}
.interview-li span {
	font-size: 1rem;
	margin: 0.5rem auto;
	display: block;
	color: #222;
}
.interview-li .content {
	padding: 0.8rem 1rem;
}
.interview-li .content span {
	padding-bottom: 0.35rem;
}
.interview-li .serial {
	background-color: #daa520;
	color: #222;
	display: flex;
	margin: 0;
	padding: 0;
}
.interview-li .serial span {
	margin: auto !important;
	font-size: 1.5rem !important;
	padding: 0.5rem;
}
.serial-present {
	background-color: #0b730b !important;
	color: #fff !important;
}
.serial-present-line {
	border-bottom: 2px solid #0b730b !important;
}
.serial-future {
	background-color: #555 !important;
	color: #fff !important;
}
.serial-future-line {
	border-bottom: 2px solid #555 !important;
}
.round-btns-section {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
}
.round-btn {
	border: 1px solid #222;
	border-radius: 5px;
	font-size: 1rem;
	cursor: pointer;
	padding: 0.35rem;
}
.round-mac-btn {
	color: #fff;
	background-color: #555;
}
.round-mac-btn:hover {
	color: #daa520;
	background-color: #222;
}
.round-del-btn {
	color: #e00a0a;
	border: 1px solid #e00a0a;
	background-color: #fff;
}
.round-del-btn:hover {
	color: #fff;
	background-color: #e00a0a;
}

@media (min-width: 768px) {
	.round-btns-section {
		grid-template-columns: repeat(4, 1fr);
	}
}
