footer {
	background-color: #555;
	border-top: 5px solid #222;
	border-bottom: 10px solid #daa520;
	min-height: 10vh;
	display: flex;
}
footer p {
	margin: auto;
	font-size: 1rem;
	text-align: center;
	color: #fff;
}
