.interview-form {
	background-color: #eee;
	padding: 15px;
	border-radius: 15px;
	margin-bottom: 2rem;
}
.interview-form label {
	display: block;
	padding-top: 1rem;
}
.date-time {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
}
.btn {
	background-color: #daa520;
	border: 0;
	cursor: pointer;
	border-radius: 5px;
	margin: 15px auto 5px auto;
}
.btn:hover {
	color: #fff;
}
.btn-outline {
	border: 2px solid #daa520;
	background-color: #fff;
}
.btn-outline:hover {
	color: #daa520;
}

@media (min-width: 768px) {
	.form-box {
		grid-column: 2/3;
	}
	.date-time {
		grid-template-columns: repeat(2, 1fr);
		gap: 0.7rem;
	}
}
